import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";

import SEO from "../components/SEO";
import ComingSoonBanner from "../components/ComingSoonBanner";
import FeatureCard from "../components/FeatureCard";
import IntermediariesContact from "../components/IntermediariesContact";
import Layout from "../components/Layout";

const { useMemo } = React;

const OurMortgages = ({ data }) => {
    const productFeatures = useMemo(
        () =>
            data.productFeatures.nodes.map((node) => ({
                order: node.frontmatter.order,
                title: node.frontmatter.title,
                image: node.frontmatter.photo,
                description: node.frontmatter.description
            })),
        [data]
    );

    return (
        <Layout
            title="Competitive buy to let mortgages"
            subtitle="Our suite of products is underpinned by straight-talking criteria with no surprises, so you’ll always know where you stand."
            titleWidth={{ xs: "100%", md: "85%", lg: "75%", xl: "67%" }}
        >
            <ComingSoonBanner />

            <Box
                sx={{
                    mt: {
                        xs: 2,
                        md: 8
                    }
                }}
            >
                <Layout.Content>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                            flexDirection: {
                                xs: "column",
                                sm: "row"
                            }
                        }}
                    >
                        <Box>
                            <Typography sx={{ fontSize: "1.875rem" }} fontWeight={500}>
                                Supporting your entrepreneurial spirit
                            </Typography>
                            <Typography
                                component="p"
                                variant="body1"
                                fontFamily="Hind"
                                sx={{ mt: 3, mb: 8 }}
                            >
                                We add depth, variety and diversity to your business offering. From
                                limited companies to portfolio landlords, HMOs to MUFBs, and from
                                first time landlord and first time buyers to existing landlords, our
                                specialism helps to boost your client offering.
                            </Typography>
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent="space-between" my={4}>
                        <Grid
                            container={true}
                            spacing={4}
                            sx={{
                                justifyContent: {
                                    xs: "center",
                                    md: "space-between"
                                }
                            }}
                        >
                            {productFeatures.slice(0, 3).map((feature) => (
                                <Grid key={feature.title} item={true} md={4} sx={{ mb: 2 }}>
                                    <FeatureCard {...feature} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box display="flex" justifyContent="space-between" my={4}>
                        <Grid container={true} spacing={4} justifyContent="center">
                            {productFeatures
                                .filter((feature, index) => [4, 6, 7].includes(index))
                                .map((feature) => (
                                    <Grid key={feature.title} item={true} md={4} sx={{ mb: 2 }}>
                                        <FeatureCard {...feature} minHeight={130} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Box>
                </Layout.Content>
            </Box>

            <IntermediariesContact />
        </Layout>
    );
};

OurMortgages.propTypes = {
    data: PropTypes.object.isRequired
};

export const query = graphql`
    query {
        productFeatures: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "our-mortgages-product-features" } } }
            sort: { order: ASC, fields: [frontmatter___order] }
        ) {
            nodes {
                frontmatter {
                    order
                    title
                    photo {
                        publicURL
                    }
                    description
                }
            }
        }
    }
`;

export const Head = () => (
    <SEO
        title="ModaMortgages: Our specialist buy to let mortgages"
        description="Competitive buy to let mortgages for every type of landlord - small or large portfolios, limited companies, individuals, first-time buyers and first-time landlords."
    />
);

export default OurMortgages;
