import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";

import { Trans } from "@chetwoodfinancial/components/I18n";

const FeatureCard = ({ title, description, image, sx, order, minHeight }) => (
    <Card
        sx={{
            display: "flex",
            height: "100%",
            overflow: "visible",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            ...sx
        }}
        variant="outlined"
    >
        <Box
            position="relative"
            display="flex"
            alignItems="center"
            bgcolor="#E9F9F9"
            sx={{
                bgcolor: "none"
            }}
        >
            <CardMedia
                component="img"
                sx={{ width: 68, margin: 2, objectFit: "contain" }}
                image={image.publicURL}
                alt={`Photograph of ${title}, ${description}`}
                height={64}
            />
        </Box>
        <CardContent
            sx={{
                justifyContent: "center",
                p: {
                    xs: 0.5,
                    md: 2
                }
            }}
        >
            <Box display="flex" alignItems="start" justifyContent="center">
                <Typography
                    component="p"
                    fontWeight={500}
                    {...{ minHeight }}
                    sx={{ fontSize: "1.375rem", minHeight }}
                >
                    <Trans
                        id={`ourMortgages.productFeaturesTitle.${order}`}
                        defaultMessage={title}
                    />
                </Typography>
            </Box>
            <Box display="flex" alignItems="start" justifyContent="center">
                <Typography fontFamily="Hind" sx={{ fontSize: "1.125rem" }}>
                    <Trans
                        id={`ourMortgages.productFeaturesDesc.${order}`}
                        defaultMessage={description}
                    />
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

FeatureCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    order: PropTypes.number.isRequired,
    sx: PropTypes.object,
    minHeight: PropTypes.number
};

FeatureCard.defaultProps = {
    sx: {},
    minHeight: 75
};

export default FeatureCard;
